import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  ReferenceField,
  Button,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ActionDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import httpClient from '../../utils/httpClient';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const CardList = props => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [record, setRecord] = React.useState(null);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const TextFilter = (props) => (
    <Filter {...props}>
      <TextInput label="holder" source="holder" alwaysOn />
      <TextInput label="last4digits" source="cardNumber" alwaysOn />
    </Filter>
  );

  const showDialogBox = (record) => {
    setRecord(record)
    handleClick()
  }

  const handleDeleteCard = async () => {
    await httpClient(`${apiUrl}/skills/cards/${record?.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
    window.location.reload(true);
    setShowDialog(false)
  };

  const ButtonField = ({source, record}) => {
    return (
      <Button label="устгах" onClick={()=>{
        console.warn(record)
        showDialogBox(record)
      }}>
        <ActionDelete color="error" />
      </Button>
    )
  }

  
  return (
    <List {...props} filters={<TextFilter/>}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="cardId" />
      <TextField source="holder" />
      <TextField source="bankName" />
      <TextField source="cardBrand" />
      <TextField source="cardNumber" />
      <ReferenceField source="userId" reference="users" label="Имэйл">
        <TextField source="email" />
      </ReferenceField>
      <ButtonField source="id">
      </ButtonField>
      <Fragment>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Are you sure?">
          <DialogTitle>
            {`Та ${record?.cardNumber} дугаартай ${record?.holder} - хэрэглэгчийн картыг устгахдаа итгэлтэй байна уу?`}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteCard} label="Тийм"  key="button">
              <ActionDelete color="error" />
            </Button>
            <Button label="Буцах" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </Datagrid>
  </List>
  )
}
