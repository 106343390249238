import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  NumberInput,
  DateTimeInput,
  Filter,
  FileField,
  FileInput,
  TextField,
  TextInput,
  EditButton,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  FunctionField,
  ReferenceField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  required,
  BooleanInput,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Хичээлийн нэр" source="title" alwaysOn />
    <TextInput label="Шошго" source="tags" alwaysOn />
</Filter>
);

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  if (status === 'comming_soon') {
    return "тун удахгүй";
  }
  return "нийтлэсэн";
}

export const CourseList = props => (
  <List {...props} filters={<TextFilter/>} >
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField source="creatorId" reference="skills/creators">
        <TextField label="creator" source="firstName" />
      </ReferenceField>
      <ReferenceField source="categoryId" reference="skills/categories">
        <TextField label="category" source="name" />
      </ReferenceField>
      <TextField label="Гарчиг" source="title" />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <DateField label="Үүсгэсэн огноо" source="createdAt" />
      <EditButton />
    </Datagrid>
  </List>
);

const CourseTitle = ({ record }) => {
  const { id } = record;
  return <span>Courses {record ? `"${id}"` : ''}</span>;
};

export const CourseEdit = props => (
  <Edit title={<CourseTitle />} {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput disabled source="id" />
      <ReferenceInput label="Creator" source="creatorId" reference="skills/creators" validate={[required()]} perPage={500}>
        <AutocompleteInput optionText="firstName" />
      </ReferenceInput>
      <ReferenceInput label="Category" source="categoryId" reference="skills/categories" validate={[required()]}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput fullWidth label="Гарчиг" source="title" validate={[required()]} />
      <TextInput multiline fullWidth rows={5} label="Танилцуулга" source="description" />
      <ImageField source="thumbnailImage" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField title="image" />
      </FileInput>
      <TextInput fullWidth label="materialLink" source="materialLink" />
      <FileInput source="documentFile" label="Хичээлийн материал" accept="application/pdf, image/*, application/zip, application/octet-stream">
        <FileField title="title" />
      </FileInput>
      <TextInput label="Хугацаа" source="totalDuration" validate={[required()]} />
      <NumberInput label="Төлбөр" source="price" validate={[required()]} />
      <NumberInput label="Хямдарсан үнэ" source="saledPrice" />
      <ArrayInput
        fullWidth
        label="Юу юунаас бүрдэж байгаа вэ?"
        source="includes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Хүлээж буй зүйлс" source="outcomes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput label="Шаардлагаууд" source="requirements">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput label="Шошго" source="tags">
        <SimpleFormIterator>
          <TextInput fullWidth label="утга" />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'comming_soon', name: 'тун удахгүй' },
        ]}
      />
      <DateTimeInput label="Үүсгэсэн огноо" source="createdAt" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const CourseCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={true} />
      <ReferenceInput label="Creator" source="creatorId" reference="skills/creators" validate={[required()]} perPage={500}>
        <AutocompleteInput optionText="firstName" />
      </ReferenceInput>
      <ReferenceInput label="Category" source="categoryId" reference="skills/categories" validate={[required()]}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput fullWidth label="Гарчиг" source="title" validate={[required()]} />
      <TextInput multiline fullWidth rows={5} label="Танилцуулга" source="description" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField title="image" />
      </FileInput>
      <FileInput source="documentFile" label="Хичээлийн материал" accept="application/pdf, image/*, application/zip, application/octet-stream">
        <FileField title="title" />
      </FileInput>
      <TextInput label="Хугацаа" source="totalDuration" validate={[required()]} />
      <NumberInput label="Төлбөр" source="price" validate={[required()]} />
      <NumberInput label="Хямдарсан үнэ" source="saledPrice" />
      <ArrayInput
        fullWidth
        label="Юу юунаас бүрдэж байгаа вэ?"
        source="includes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Хүлээж буй зүйлс" source="outcomes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Шаардлагууд" source="requirements">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
          { id: 'comming_soon', name: 'тун удахгүй' },
        ]}
      />
    </SimpleForm>
  </Create>
);
