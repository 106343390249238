import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ExportButton,
  SelectInput,
  DateInput,
} from 'react-admin';
import { PROMO_STATUSES } from '../constants';
import ActivateButton from './ActivateButton';

const CodeFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Email" source="email" alwaysOn />
      <TextInput label="Phone Number" source="phoneNumber" alwaysOn />
      <SelectInput
        label="Status"
        source="status"
        choices={PROMO_STATUSES}
        alwaysOn
      />
      <DateInput label="Огноо" source="createdAt" alwaysOn />
  </Filter>
);

const PromoCodeActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
    </Toolbar>
  );
};

export const PromoCodeList = props => (
  <List {...props} filters={<CodeFilter/>} actions={<PromoCodeActions props={props} />}>
    <Datagrid optimized>
      <TextField label="Утасны дугаар" source="phoneNumber" />
      <TextField label="И-майл" source="email" />
      <TextField label="Статус" source="status" />
      <ActivateButton activate />
      <ActivateButton activate={false} />
    </Datagrid>
  </List>
);
