import React, { useState } from 'react';
import httpClient from '../utils/httpClient';
import { useNotify, useDataProvider, Button } from 'react-admin';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const ActivateButton = ({ record, activate }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
        dataProvider.patch(`1k-promo/codes/${record.code}/${activate ? 'activate' : 'deactivate'}`, {})
        .then(() => {
          if (activate) {
            httpClient(`${apiUrl}/1k-promo/check`, {
              method: 'POST',
              body: JSON.stringify({
                code: record.code,
                email: record.email,
              }),
            }).then(( response ) => {
              if ( response.status === 200 && !!response?.json?.user ) {
                notify(`User subscription updated`);
              } else {
                notify(`User subscription not updated`);
              }
            }).catch((e) => {
              console.log(e)
              notify('Error: User subscription not updated', 'warning')
            });
          }
            // We have to call get list in order to refresh list data
            dataProvider.getList('1k-promo/codes', { 
              pagination:{ 
                page: 1, perPage: 10,
              }, 
              sort: {
                field: "id",
                order: "ASC",
              } 
            }).then(response => {
              notify(`Code ${activate ? 'activated' : 'deactivated'}`);
          });
        })
        .catch((e) => {
          console.log(e)
            notify('Error: code not activated', 'warning')
        })
        .finally(() => {
            setLoading(false);
        });
    };
    return <Button label={`${activate ? 'Activate' : 'Deactivate'}`} onClick={handleClick} disabled={loading} />;
};

export default ActivateButton;