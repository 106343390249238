const roles = {
  'SUPER_ADMIN': 'super',
  'DATABASE_ADMIN': 'database',
  'SALES_ADMIN': 'sales',
  'PRODUCTION_ADMIN': 'production',
  'INTERN_ADMIN': 'intern',
};

export const accessableAdmins = (permissions, accessibleAdmins) => {
  if(accessibleAdmins?.includes(roles[permissions])) {
    return true
  }
  return false
};
