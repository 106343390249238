import React from 'react';

export const Welcome = (props) => {

  const userName = localStorage.getItem('username').split('@')[0].toUpperCase();

  return(
    <React.Fragment >
      <div style={{flex: 1, backgroundColor: '#fff'}}>
         <h3 style={{fontSize: 40, paddingLeft: 50, paddingTop: 50}}>{`Сайнуу,  ${userName}  😀`}</h3>
      </div>
    </React.Fragment>
);}
