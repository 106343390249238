import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const i18nProvider = polyglotI18nProvider(() =>
  englishMessages,
  'en', // Default locale
  {
    allowMissing: true,
    onMissingKey: key => {
      console.log(`missing: ${key}`);
      return key;
    }
  }
);

export default i18nProvider;
