import React from 'react';
import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  ReferenceField
} from 'react-admin';

const CustomFilter = (props) => (
  <Filter {...props}>
      <TextInput label="TransactionID" source="transactionId" alwaysOn />
  </Filter>
);

const InAppPurchaseList = props => (
  <List {...props} filters={<CustomFilter/>} >
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField label="Имэйл" source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField label="Апп" source="app" />
      <TextField label="Environment" source="environment" />
      <TextField label="ProductId" source="productId" />
      <TextField label="Transaction ID" source="transactionId" />
      <BooleanField label="Validated?" source="isValidated" />
      <BooleanField label="Status" source="status" />
      <DateField label="Starts At" source="startsAt" showTime />
      <DateField label="Expires At" source="expiresAt" showTime />
    </Datagrid>
  </List>
);

export {
  InAppPurchaseList,
}
