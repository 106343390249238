import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Create,
  CreateButton,
  Edit,
  Filter,
  ImageField,
  FileField,
  FileInput,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  SelectInput,
  email,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Нэр" source="firstName" alwaysOn />
  </Filter>
);


const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const CreatorList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Овог" source="lastName" />
      <TextField label="Нэр" source="firstName" />
      <TextField label="И-майл" source="email" />
      <TextField label="Мэргэжил" source="profession" />
      <TextField label="Төрөл" source="type" />
      <ImageField label="Зураг" source="avatar" />
      <EditButton />
    </Datagrid>
  </List>
);

const CreatorTitle = ({ record }) => {
  const { firstName } = record;
  return <span>Creator {record ? `"${firstName}"` : ''}</span>;
};

export const CreatorEdit = props => (
  <Edit title={<CreatorTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Овог" source="lastName" />
      <TextInput label="Нэр" source="firstName" />
      <TextInput label="И-майл" source="email" validate={email()} />
      <TextInput label="Мэргэжил" source="profession" />
      <TextInput multiline fullWidth rows={5} label="Намтар" source="bio" />
      <ImageField source="avatar" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="төрөл"
        source="type"
        defaultValue="expert"
        choices={[
          { id: 'master', name: 'master' },
          { id: 'expert', name: 'expert' },
          { id: 'foreign', name: 'foreign' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const CreatorCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Овог" source="lastName" />
      <TextInput label="Нэр" source="firstName" />
      <TextInput label="И-майл" source="email" validate={email()} />
      <TextInput label="Мэргэжил" source="profession" />
      <TextInput multiline fullWidth rows={5} label="Намтар" source="bio" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="төрөл"
        source="type"
        defaultValue="expert"
        choices={[
          { id: 'master', name: 'master' },
          { id: 'expert', name: 'expert' },
          { id: 'foreign', name: 'foreign' },
        ]}
      />
    </SimpleForm>
  </Create>
);
