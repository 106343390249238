import React from 'react';
import {
  List,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  ReferenceField,
  ReferenceInput,
  Filter,
  AutocompleteInput,
  DateField
} from 'react-admin';

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="preEmail" label="Өмнөх email" alwaysOn />
    <TextInput source="newEmail" label="Дараах email" alwaysOn />
  </Filter>
);

const optionRenderer = choice => `${choice.email} ${choice.social}`;

export const UserSubscriptionTransferList = props => (
  <List {...props} filters={<Filters />}>
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField source="preUserId" reference="users" label="өмнөх хаяг">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="newUserId" reference="users" label="шилжүүлсэн хаяг">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="subscriptionPlanId" reference="subscriptionPlans" label="subscription">
        <TextField source="type" />
      </ReferenceField>
      <DateField source="startDate" label="эхэлсэн" />
      <DateField source="endDate" label="дуусах" />
      <DateField source="createdAt" showTime label="шилжүүлсэн" />
      <TextField source="adminEmail" label="шилжүүлэн admin" />
    </Datagrid>
  </List>
);

export const UserSubscriptionTransferCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="preUserId" reference="users" label="эрх шилжүүлэх хүний имэйл хаяг" fullWidth>
        <AutocompleteInput source="email" optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput source="newUserId" reference="users" label="шилжүүлэгдэх имэйл хаяг" fullWidth>
        <AutocompleteInput source="email" optionText={optionRenderer} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
