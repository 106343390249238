import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  ReferenceField,
  Filter,
  Button
} from 'react-admin';
import httpClient from '../utils/httpClient';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="email" label="Имайл" alwaysOn />
  </Filter>
);


  const handleDeactivate = async (record, status) => {
    const data = { status }
    httpClient(`${apiUrl}/skills/userSubscriptions/schedules/${record.id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }))
    window.location.reload(true);
  };

    const ButtonField = ({source, record}) => {
    const status = record.status === 'ACTIVE' ? 'CANCELLED' : 'ACTIVE';
    return (
      <Button label={record.status === 'ACTIVE' ? 'Deactivate' : 'Activate'} onClick={()=>{
        handleDeactivate(record, status)
      }}>
      </Button>
    )
  }

export const UserSubscriptionScheduleList = props => (
  <List {...props} filters={<Filters /> }>
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users" label="хаяг">
        <TextField source="email" />
      </ReferenceField>
      <DateField source="startDate" label="эхлэх" />
      <DateField source="endDate" label="таталт хийх" />
      <TextField source="cardId" label="картын id" />
      <TextField source="status" label="cтатус" />
      <ButtonField source="id" label="эрх өөрчлөх" />
    </Datagrid>
  </List>
);
