import React from 'react';
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  FileInput,
  FileField,
  required,
} from 'react-admin';
import { FEATURES } from '../constants/Feature';

const optionRenderer = choice => `${choice.email} ${choice.social}`;

export const FeatureTargetList = props => (
  <List {...props} title="Feature Targets">
    <Datagrid optimized>
      <TextField source="id" />
      <TextField label="Feature Name" source="feature" />
      <ReferenceField source="userId" reference="users">
        <TextField label="Нэр" source="firstName" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const FeatureTargetCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput label="Feature Name" source="feature" choices={FEATURES} validate={[required()]} />
      <ReferenceInput source="userId" reference="users">
        <AutocompleteInput optionText={optionRenderer} options={{ suggestionsContainerProps: { disablePortal: true } }} />
      </ReferenceInput>
      <FileInput source="file" label="User segment" accept="text/csv">
        <FileField source="file" title="CSV file" />
      </FileInput>
    </SimpleForm>
  </Create>
);
