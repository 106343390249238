import React from 'react';

// icons
import SettingsIcon from '@material-ui/icons/Settings';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import WbSunny from '@material-ui/icons/WbSunny';
import AccountBox from '@material-ui/icons/AccountBoxRounded';

// core libraries
import './App.css';
import authProvider from './AuthProvider';
import { Admin, Resource } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import dtProvider from './utils/dataProvider';
import { accessableAdmins } from './utils/adminRoles';

// components
import { UserList, UserEdit, UserCreate } from './components/Users';
import {
  UserSubscriptionList,
  UserSubscriptionEdit,
  UserSubscriptionCreate,
} from './components/UserSubscription';
import {
  UserSubscriptionTransferList,
  UserSubscriptionTransferCreate
} from './components/UserSubscriptionTransfer';
import {
  UserSubscriptionScheduleList,
} from './components/UserSubscriptionSchedule';
import {
  SubscriptionPlanList,
  SubscriptionPlanEdit,
  SubscriptionPlanCreate,
} from './components/SubscriptionPlan';
import { UserCount } from './components/UserCount';
import { Welcome } from './components/Welcome';
import { PaymentList } from './components/Payment';
import { TransactionList, TransactionCreate } from './components/Transaction';
import { PaymentLogList } from './components/PaymentLog';
import { CardList } from './components/skills/UserCards';

import { NotificationList, NotificationCreate } from './components/Notification';
import { AdminUserList, AdminUserEdit, AdminUserCreate } from './components/AdminUser';
import { FeatureTargetList, FeatureTargetCreate } from './components/FeatureTarget';
import { CouponList, CouponCreate, CouponEdit, BulkCouponCreate } from './components/Coupon';
import {
  DeploymentConfigList,
  DeploymentConfigEdit,
  DeploymentConfigCreate,
} from './components/DeploymentConfig';
import Layout from './components/TomYoLayout';
import {
  CategoryList as SkillsCategoryList,
  CategoryCreate as SkillsCategoryCreate,
  CategoryEdit as SkillsCategoryEdit,
} from './components/skills/Category';
import {
  CreatorList as SkillsCreatorList,
  CreatorCreate as SkillsCreatorCreate,
  CreatorEdit as SkillsCreatorEdit,
} from './components/skills/Creator';
import {
  CourseList as SkillsCourseList,
  CourseCreate as SkillsCourseCreate,
  CourseEdit as SkillsCourseEdit,
} from './components/skills/Course';
import {
  VideoList as SkillsVideoList,
  VideoCreate as SkillsVideoCreate,
  VideoEdit as SkillsVideoEdit,
} from './components/skills/Video';
import {
  PurchaseOrderList,
  PurchaseOrderCreate,
} from './components/skills/PurchaseOrder';
import { PromoCodeList } from './components/PromoCode';
import i18nProvider from './utils/i18n/i18nProvider';
import {
  InAppPurchaseList,
} from './components/InAppPurchase';

import { CarrierBillingSubscriptionList } from './components/skills/CarrierBillingSubscription';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#36454F"
    },
  },
});

function App() {
  return (
    <Admin theme={theme} layout={Layout} dataProvider={dtProvider} authProvider={authProvider} i18nProvider={i18nProvider}>
      {permissions => [
        <Resource
          name="/"
          icon={WbSunny}
          options={{ label: 'Welcome' }}
          list={Welcome}
        />,

        // Хэрэглэгч
        accessableAdmins(permissions, ['super', 'database', 'sales', 'intern']) &&
          <Resource name="user" icon={AccountBox} options={{ label: 'Хэрэглэгч', isMenuParent: true }} />,
          <Resource
              name="users"
              options={{ label: 'Хэрэглэгч', menuParent: 'user' }}
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
            />,
          <Resource
            name="Allusers"
            options={{ label: 'Хэрэглэгчийн тоо', menuParent: 'user' }}
            list={UserCount}
          />,
        accessableAdmins(permissions, ['super', 'database']) &&
          <Resource
            name="notifications"
            options={{ label: 'SMS Илгээх', menuParent: 'user' }}
            list={NotificationList}
            create={NotificationCreate}
          />,
        accessableAdmins(permissions, ['super']) &&
          <Resource
            name="adminUsers"
            options={{ label: 'Админ хэрэглэгч', menuParent: 'user' }}
            list={AdminUserList}
            edit={AdminUserEdit}
            create={AdminUserCreate}
          />,
        accessableAdmins(permissions, ['super', 'database', 'sales', 'intern']) &&
          <Resource
            name="1k-promo/codes"
            options={{ label: '1k Users', menuParent: 'user' }}
            list={PromoCodeList}
          />,

        // Subscription
        accessableAdmins(permissions, ['super', 'database', 'sales']) &&
        <Resource name="sales" icon={ShoppingCart} options={{ label: 'Sales', isMenuParent: true }} />,
        <Resource
          name="userSubscriptions"
          options={{ label: 'Хэрэглэгчийн төлбөр', "menuParent": "sales" }}
          list={UserSubscriptionList}
          edit={UserSubscriptionEdit}
          create={UserSubscriptionCreate}
        />,
        <Resource
          name="skills/userSubscriptions/transfers"
          options={{ label: 'Эрх шилжүүлэх', "menuParent": "sales" }}
          list={UserSubscriptionTransferList}
          create={UserSubscriptionTransferCreate}
        />,
        <Resource
          name="skills/userSubscriptions/schedules"
          options={{ label: 'Recurring schedules', "menuParent": "sales" }}
          list={UserSubscriptionScheduleList}
        />,
        <Resource
          name="subscriptionPlans"
          options={{ label: 'Төлбөрийн төрөл', "menuParent": "sales" }}
          list={SubscriptionPlanList}
          edit={SubscriptionPlanEdit}
          create={SubscriptionPlanCreate}
        />,
        <Resource
          name="payments"
          options={{ label: 'Төлбөрийн гүйлгээ', "menuParent": "sales" }}
          list={PaymentList}
        />,
        <Resource
          name="bankTransactions"
          options={{ label: 'Банкны гүйлгээ', "menuParent": "sales" }}
          list={TransactionList}
          create={TransactionCreate}
        />,
        <Resource
          name="paymentLogs"
          options={{ label: 'Төлбөрийн төлөлтийн түүх', "menuParent":"sales" }}
          list={PaymentLogList}
        />,
        <Resource
          name="skills/cards"
          options={{ label: 'Хэрэглэгчийн карт', "menuParent":"sales" }}
          list={CardList}
        />,
        <Resource
          name="coupons"
          options={{ label: 'Купон', "menuParent": "sales" }}
          list={CouponList}
          edit={CouponEdit}
          create={CouponCreate}
        />,
        <Resource
          name="bulk/coupons"
          options={{ label: 'Олон купон үүсгэх', "menuParent": "sales" }}
          create={BulkCouponCreate}
        />,
        <Resource
          name="inAppPurchases"
          options={{ label: 'In-App Purchase', "menuParent": "sales" }}
          list={InAppPurchaseList}
        />,
        <Resource
        name="skills/carrierBillingSubscriptions"
        options={{ label: 'Carrier Billing Subscription', "menuParent":"sales" }}
        list={CarrierBillingSubscriptionList}
        />,

        // Skills
        <Resource name="tomyo-skills" icon={VerifiedUser} options={{ label: 'Skills', isMenuParent: true }} />,
        <Resource
          name="skills/categories"
          options={{ label: 'Ангилал', menuParent: 'tomyo-skills' }}
          list={SkillsCategoryList}
          edit={SkillsCategoryEdit}
          create={SkillsCategoryCreate}
        />,
        <Resource
          name="skills/creators"
          options={{ label: 'Creator', menuParent: 'tomyo-skills' }}
          list={SkillsCreatorList}
          edit={SkillsCreatorEdit}
          create={SkillsCreatorCreate}
        />,
        <Resource
          name="skills/courses"
          options={{ label: 'Хичээл', menuParent: 'tomyo-skills' }}
          list={SkillsCourseList}
          edit={SkillsCourseEdit}
          create={SkillsCourseCreate}
        />,
        <Resource
          name="skills/videos"
          options={{ label: 'Видео', menuParent: 'tomyo-skills' }}
          list={SkillsVideoList}
          edit={SkillsVideoEdit}
          create={SkillsVideoCreate}
        />,
        accessableAdmins(permissions, ['super', 'database', 'sales']) &&
        <Resource
          name="skills/purchaseOrders"
          options={{ label: 'Захиалга', menuParent: 'tomyo-skills' }}
          list={PurchaseOrderList}
          create={PurchaseOrderCreate}
        />,

        accessableAdmins(permissions, ['super']) &&
        <Resource name="codeRelated" icon={SettingsIcon} options={{ label: 'Тохиргоо', isMenuParent: true }} />,
        <Resource
          name="featureTargets"
          options={{ label: 'Pilot features', "menuParent": "codeRelated" }}
          icon={FiberNewIcon}
          list={FeatureTargetList}
          create={FeatureTargetCreate}
        />,
        <Resource
          name="deploymentConfigs"
          icon={SettingsIcon}
          options={{ label: 'Deployment Configs', "menuParent": "codeRelated" }}
          list={DeploymentConfigList}
          edit={DeploymentConfigEdit}
          create={DeploymentConfigCreate}
        />,
      ]}
    </Admin>
  );
}

export default App;
