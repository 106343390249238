import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ExportButton,
  FunctionField,
} from 'react-admin';

const getStatus = (success) => {
  if (success === 0) {
    return "Амжилттай";
  }
  return "Амжилтгүй";
}

const TrxIdFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Гүйлгээний ID" source="invoiceNo" alwaysOn />
      <TextInput label="Хариу(Qpay Id)" source="providerResponseDesc" alwaysOn />
  </Filter>
);

const PaymentLogActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
    </Toolbar>
  );
};

export const PaymentLogList = props => (
  <List {...props} filters={<TrxIdFilter/>} actions={<PaymentLogActions props={props} />}>
    <Datagrid optimized>
      <TextField label="Гүйлгээний ID" source="invoiceNo" />
      <FunctionField label="Статус" render={record => getStatus(record.success)} />
      <TextField label="Алдааны код" source="errorCode" />
      <TextField label="Алдааны тайлбар" source="errorDesc" />
      <TextField label="Картын дугаар" source="cardNumber" />
      <TextField label="Баталгаажуулалтын хариу код" source="providerResponseCode" />
      <TextField label="Баталгаажуулалтын хариу тайлбар" source="providerResponseDesc" />
    </Datagrid>
  </List>
);
