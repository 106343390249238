export const ADMIN_ROLES = [
  { id: 'SUPER_ADMIN', name: 'SUPER_ADMIN' },
  { id: 'DATABASE_ADMIN', name: 'DATABASE_ADMIN' },
  { id: 'SALES_ADMIN', name: 'SALES_ADMIN' },
  { id: 'PRODUCTION_ADMIN', name: 'PRODUCTION_ADMIN' },
  { id: 'INTERN_ADMIN', name: 'INTERN_ADMIN' },
];

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  DATABASE_ADMIN: 'DATABASE_ADMIN',
  SALES_ADMIN: 'SALES_ADMIN',
  PRODUCTION_ADMIN: 'PRODUCTION_ADMIN',
  INTERN_ADMIN: 'INTERN_ADMIN',
};
